@if (url) {
  @if (type === 'cta') {
    <a
      mat-raised-button
      color="primary"
      [routerLink]="url"
      [queryParams]="queryParams"
    >
      <mat-icon class="mr-2">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </a>
  } @else {
    <a mat-menu-item [routerLink]="url" [queryParams]="queryParams">
      <mat-icon color="primary">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </a>
  }
} @else {
  @if (type === 'cta') {
    <button mat-raised-button color="primary" (click)="onClick()">
      <mat-icon class="mr-2">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </button>
  } @else {
    <button mat-menu-item (click)="onClick()">
      <mat-icon color="primary">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </button>
  }
}
