import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-action-button',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, RouterModule],
  templateUrl: './action-button.component.html',
  styleUrl: './action-button.component.scss',
})
export class ActionButtonComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() type: 'cta' | 'menu' = 'cta';
  @Input() url?: string;
  @Input() queryParams?: { [key: string]: string };
  @Output() action? = new EventEmitter<void>();

  constructor() {}

  onClick() {
    if (this.action) {
      this.action.emit();
    }
  }
}
