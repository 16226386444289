import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActionButton, Breadcrumb } from 'src/app/models/utils';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { BackButtonComponent } from 'src/app/components/shared/back-button/back-button.component';
import { BreadcrumbsComponent } from 'src/app/components/shared/breadcrumbs/breadcrumbs.component';
import { ActionButtonComponent } from 'src/app/components/layout/action-button/action-button.component';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AppMaterialModule,
    RowComponent,
    ColComponent,
    RouterModule,
    BackButtonComponent,
    BreadcrumbsComponent,
    ActionButtonComponent,
  ],
})
export class TitleComponent {
  @Input() title: string;
  @Input() breadcrumbs: Breadcrumb[];
  @Input() actionButton: ActionButton;
  @Input() actionButtons: ActionButton[];
  @Input() hasBackButton: boolean = false;

  hasActionButton() {
    return this.actionButton || this.actionButtons;
  }
}
